import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Main from "./layout/Main";
import routes from "./routes";
import store from "./store";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        const currentUrl = window.location.pathname;
        const paramValue = currentUrl.substring(1);
        if (
            paramValue === "kona-1" ||
            paramValue === "kona-1/form" ||
            paramValue === "kona-1/thankyou" ||
            paramValue === "kona-2" ||
            paramValue === "kona-2/form" ||
            paramValue === "kona-2/thankyou" ||
            paramValue === "konazakelijk" ||
            paramValue === "konazakelijk/form" ||
            paramValue === "konazakelijk/thankyou"
        ) {
            document.title = "Hyundai Kona";
        } else {
            document.title = "Hyundai i10";
        }
    });
    return (
        <>
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        {routes.map((route, index) => {
                            switch (route.layout) {
                                case "main":
                                    return (
                                        <Route
                                            exact
                                            path={route.path}
                                            key={index}
                                        >
                                            <Main>
                                                <route.component />
                                            </Main>
                                        </Route>
                                    );
                            }
                        })}
                        <Redirect exact from="/" to="/i10-2" />
                    </Switch>
                </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;
