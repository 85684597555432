import QuestionModal from "../components/QuestionModal";
import QuestionModal_i10_2 from "../components/QuestionModal_i10-2";
import QuestionModal_kona1 from "../components/QuestionModal_kona-1";
import QuestionModal_kona2 from "../components/QuestionModal_kona-2";
import QuestionModal_konaZakelijk from "../components/QuestionModal_kona-Zakelijk";
import MainView from "../views/MainView";
import MainView2 from "../views/MainView_i10-2";
import MainViewKona1 from "../views/MainView_kona-1";
import MainViewKona2 from "../views/MainView_kona-2";
import MainViewKonaZakelijk from "../views/MainView_kona-Zakelijk";
import ThankYouPage from "../views/ThankYouPage";

let routes = [
    // {
    //     path: "/i10-1",
    //     component: MainView,
    //     layout: "main",
    // },
    {
        path: "/i10-2",
        component: MainView2,
        layout: "main",
    },
    // {
    //     path: "/kona-1",
    //     component: MainViewKona1,
    //     layout: "main",
    // },
    // {
    //     path: "/kona-2",
    //     component: MainViewKona2,
    //     layout: "main",
    // },
    // {
    //     path: "/konazakelijk",
    //     component: MainViewKonaZakelijk,
    //     layout: "main",
    // },
    // {
    //     path: "/konazakelijk/form",
    //     component: QuestionModal_konaZakelijk,
    //     layout: "main",
    // },
    // {
    //     path: "/form",
    //     component: QuestionModal,
    //     layout: "main",
    // },
    {
        path: "/i10-2/form",
        component: QuestionModal_i10_2,
        layout: "main",
    },
    // {
    //     path: "/kona-1/form",
    //     component: QuestionModal_kona1,
    //     layout: "main",
    // },
    // {
    //     path: "/kona-2/form",
    //     component: QuestionModal_kona2,
    //     layout: "main",
    // },
    // {
    //     path: "/thankyou",
    //     component: ThankYouPage,
    //     layout: "main",
    // },
    {
        path: "/i10-2/thankyou",
        component: ThankYouPage,
        layout: "main",
    },
    // {
    //     path: "/kona-1/thankyou",
    //     component: ThankYouPage,
    //     layout: "main",
    // },
    // {
    //     path: "/kona-2/thankyou",
    //     component: ThankYouPage,
    //     layout: "main",
    // },
    // {
    //     path: "/konazakelijk/thankyou",
    //     component: ThankYouPage,
    //     layout: "main",
    // },
];
export default routes;
